import React from 'react';
import taskLogo from "./tasks_icons_android/circle_calendar_android.svg";
import BaseTask from './BaseTaskAndroid';
import qs from 'querystring'
import moment from 'moment'
import check from "./tasks_icons_android/check.svg";
import { isAppInstalled } from './insta_custom_views/utils_android/AndroidMethods';
import { addRemovedOrder } from './insta_custom_views/utils_android/CookieHelper';
import { getMoneyType } from './insta_custom_views/utils_android/UtilsAndroid';


class FifthTask extends BaseTask {
   onResume(){
        if (!this.state.isFinished && (this.props.isEnabled || this.state.isEnabled) && this.startTaskTime != -1){
            this.startTaskTime = -1
            this.perfomThirdDay()
            // var currentTime = new Date().getTime() / 1000
            // if (currentTime - 30 >= this.startTaskTime){
            //     console.log("case 2 startPerfom")
            //     this.startTaskTime = -1
            //     this.perfomThirdDay()
            // } else {
            //     this.startTaskTime = -1
            //     this.props.showErrorResponse("Проведите в приложении минимум 30 секунд. При нажатии продолжить, отсчет времени пойдет заново.", "Продолжить", this.onButtonClick)
            // }
        }

        //testing
        // if (!this.state.isFinished && (this.props.isEnabled || this.state.isEnabled)){
        //     this.finishTask()
        // }
   }
    constructor(props){
        super(props)
        this.startTaskTime = -1
        this.state = {
            isFinished : false,
            isReadyStart : false,
            btn : 'Открыть',
            isEnabled : this.props.isEnabled
        }
        window.fifthTask = this
        this.type = 3
       
    }
    componentDidMount(){

        console.log("componentDidMount 5")
        var modified = this.props.modified
        if (modified){
            var timestampModified = moment.unix(new Date(modified).getTime() / 1000 + 259200);
            fetch("https://app-coins.ru/api/getDate", {
                method: 'POST',
                headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
                body: qs.stringify(this.props.params)
            }).then((response) => response.json())
            .then((responseData) => {
                var currentDate = moment.unix(responseData.time / 1000);
                if (timestampModified <= currentDate) {
                    this.setState({date3day : timestampModified.format("DD.MM.YYYY"), isReadyStart : true, isEnabled : true})
                } else {
                    this.setState({date3day : timestampModified.format("DD.MM.YYYY"), isReadyStart : false})
                }
            })
            
        }
        var progress = this.props.progress
        if (progress){
            // progress = progress.split(",")
            var element = parseInt(progress[this.type])
            if (element == 1){
                this.finishTask()
            }
        }
    }
    
    perfomThirdDay = () => {        
        fetch("https://app-coins.ru/api/perfomThirdDay", {
                method: 'POST',
                headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
                body: qs.stringify(this.props.params)
            }).then((response) => response.json())
            .then((responseData) => {
                if (responseData.response == 1) {
                    this.finishTask()
                    this.props.updateMoney(this.props.priceThirdDay)
                } else {
                    addRemovedOrder(this.props.task.bundle)
                    this.props.showErrorResponse(responseData.status, "Понятно", this.addRemovedOrder)
                }
                this.loadCaptcha()
                this.updateLastResponse("perfomThirdDay", responseData)
                console.log("perfomThirdDay" + JSON.stringify(responseData))
            })
    }
    onButtonClick = () => {
        if (isAppInstalled(this.props.task.bundle)){
            this.startTaskTime = new Date().getTime() / 1000
            this.openApp(this.props.task.bundle)
        } else {
            addRemovedOrder(this.props.task.bundle)
            this.props.showErrorResponse("Вы удаляли приложение за эти 3 дня, поэтому его выполнение невозможно.", "Понятно", this.addRemovedOrder)
        }
        
        
        // if (!isOpened){
        //     this.showAndroidToast("Вы удаляли приложение за эти 3 дня, поэтому его выполнение невозможно.")
        // }
    }
    addRemovedOrder = () => {
        this.props.onBack("active")
    }
    componentDidUpdate(){
       
        if (!this.state.isFinished && this.state.isEnabled && !this.state.date3day){
            var date3 = moment.unix(new Date().getTime() / 1000 + 259200);
            this.setState({date3day : date3.format("DD.MM.YYYY"),isReadyStart : false})
        }
    }
    render(){
      
            return (
                <div className={this.state.isFinished ? 'finished_task_bg' : this.state.isEnabled && this.props.modified && this.state.isReadyStart ? 'block' : 'not_ready'}>
                    <div className="flex padding_16">
                        <div className="img_icon_container">
                            <img width="40" height="40" className="icon_task_type" src={this.state.isFinished ? check : taskLogo} alt=""/>
                        </div>
                        
                        <div className="block padding_left_16">
                            <p className="task_name">Откройте приложение на 3 день и пользуйтесь им <b>минимум 30 секунд.</b></p>
                            <p className={this.state.date3day ? 'task_name' : 'not_display'}>Будет доступно: <strong>{this.state.date3day}</strong></p>
                            <div className="flex justify_content">
                                <button onClick={this.onButtonClick}  style={this.state.isFinished ? {marginLeft : '0'} : {}} disabled={this.props.modified ? !(this.state.isReadyStart && this.state.isEnabled) : true}
                                className={this.state.isFinished ? 'finished_btn' : 'task_btn'}>{this.state.btn}</button>
                                <div className="text_task_money flex">+{this.props.priceThirdDay}{getMoneyType(this.props.isUpmob)}</div>
                            </div>

                        </div>

                    </div>
                    <div className="line"></div>
             </div>

            ); 
        
        
    }
}
export default FifthTask;