/* eslint-disable eqeqeq */
import React from 'react';
import taskLogo from "./tasks_icons_android/circle_play_android.svg";
import BaseTask from './BaseTaskAndroid';
import check from "./tasks_icons_android/check.svg";
import qs from 'querystring'
import { ReCaptcha } from 'react-recaptcha-v3'
import { openAppWithParams } from './insta_custom_views/utils_android/AndroidMethods';
import { getGoogleId } from './insta_custom_views/utils_android/CookieHelper';
import ym from 'react-yandex-metrika';
import { getMoneyType } from './insta_custom_views/utils_android/UtilsAndroid';

class SecondTask extends BaseTask {
    finishTask(){
        this.setState({isFinished : true, btn : "Выполнено"})
        this.props.completedTask()
    }
   onResume(){
       if (this.startTaskTime != -1){
            //this.setState({isUpdateCaptcha : true})
            this.verifyCallback()
       }
        
        
        // if (!this.state.isFinished && (this.props.isEnabled || this.state.isEnabled)){
        //     this.finishTask()
        // }
    console.log("onResume SecondTask this.props.task2Enabled = " + (this.props.isEnabled || this.state.isEnabled))
   }
    constructor(props){
        super(props)
        this.startTaskTime = -1
        this.state = {
            isFinished : (this.props.progress && this.props.progress[0] == 1),
            isReadyStart : false,
            btn : 'Запустить',
            isUpdateCaptcha : false
        }
        window.secondTask = this
        this.type = 0
    }
    verifyCallback = (recaptchaToken) => {
        this.setState({isUpdateCaptcha : false})
        if (recaptchaToken){
            this.props.params.timestamp = recaptchaToken
        }
       
        if (!this.state.isFinished && (this.props.isEnabled || this.state.isEnabled) && this.startTaskTime != -1){
            var currentTime = new Date().getTime() / 1000
            if (currentTime - 30 >= this.startTaskTime){
                console.log("case 2 startPerfom")
                this.startTaskTime = -1
                this.startPerfom()
            } else if (currentTime - 2 >= this.startTaskTime) {
                this.startTaskTime = -1
                this.props.showErrorResponse("Проведите в приложении минимум 30 секунд. При нажатии продолжить, отсчет времени пойдет заново.", "Продолжить", this.onButtonClick)
                console.log("case 2 showErrorBanner")
            }
        }
    }
    
    startPerfom = () => {
        console.log("startPerfom params = " + JSON.stringify(this.props.params))
        fetch("https://app-coins.ru/api/startPerfom", {
                method: 'POST',
                headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
                body: qs.stringify(this.props.params)
            }).then((response) => response.json())
            .then((responseData) => {
                if (responseData.response == 1) {
                    ym(86575405,'reachGoal','startPerfom')
                    this.finishTask()
                    this.props.updateMoney(this.props.priceInstall)
                } else {
                    this.props.showErrorResponse(responseData.status, "Понятно")
                }
                this.loadCaptcha()
                this.updateLastResponse("startPerfom", responseData)
                console.log("startPerfom" + JSON.stringify(responseData))
            })
    }
    
    onButtonClick = () => {
        console.log("onButtonClick 2")
        
        this.startTaskTime = new Date().getTime() / 1000

        if (this.props.task.sdk_tasks_full_price > 0){
            openAppWithParams(this.props.task.bundle, getGoogleId(), this.props.task._id)
        } else {
            this.openApp(this.props.task.bundle)
        }
        
    }
    componentDidUpdate(){
        if (this.props.isEnabled && !this.state.isReadyStart){
            this.setState({isReadyStart : true})
        }
        
    }
    render(){
      
            return (
                <div className={this.state.isFinished ? 'finished_task_bg' : this.props.isEnabled || this.state.isEnabled ? 'block' : 'not_ready'}>
                    <div className="flex padding_16">
                        <div className="img_icon_container">
                            <img width="40" height="40" className="icon_task_type" src={this.state.isFinished ? check : taskLogo} alt=""/>
                        </div>
                        
                        <div className="block padding_left_16">
                            <p className="task_name">Запустите приложение минимум на 30 сек.</p>
                            <div className="flex justify_content">
                                <button style={this.state.isFinished ? {marginLeft : '0'} : {}} onClick={this.onButtonClick} disabled={(this.state.isFinished || !this.state.isReadyStart) && !(this.props.isEnabled || this.state.isEnabled)}
                                className={this.state.isFinished ? 'finished_btn' : 'task_btn'}>{this.state.isFinished ? "Выполнено" : this.state.btn}</button>
                                <div className="text_task_money">+{this.props.priceInstall}{getMoneyType(this.props.isUpmob)}</div>
                            </div>

                        </div>

                    </div>
                    <div className="line"></div>
                    {this.state.isUpdateCaptcha && <ReCaptcha
                        sitekey="6LdFRuEUAAAAAGBfnPHHxCvPSn8bl5ISdZSLiu7_"
                        action='homepage'
                        verifyCallback={this.verifyCallback}/>
                    } 
                </div>
            ); 
        
        
    }
}
export default SecondTask;