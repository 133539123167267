/* eslint-disable eqeqeq */
import React from 'react';
import taskLogo from "./tasks_icons_android/circle_like_android.svg";
import BaseTask from './BaseTaskAndroid';
import qs from 'querystring'
import check from "./tasks_icons_android/check.svg";
import { AppsType, AppsUrls, getMoneyType } from './insta_custom_views/utils_android/UtilsAndroid';


class FouthTask extends BaseTask {
    onResume() {
        if (!this.state.isFinished && (this.props.isEnabled || this.state.isEnabled) && this.startTaskTime != -1) {
            var currentTime = new Date().getTime() / 1000
            if (currentTime - 5 >= this.startTaskTime) {
                console.log("case 2 startPerfom")
                this.perfomGoodReviewTop()
            } else if (currentTime - 2 >= this.startTaskTime) {
                this.startTaskTime = -1
                this.props.showErrorResponse("Пожалуйста, вернитесь на страницу с приложением и лайкните минимум 5 положительных отзывов.", "Лайкнуть", this.onButtonClick)
                console.log("case 2 showErrorBanner")
            }
        }

        //testing
        // if (!this.state.isFinished && (this.props.isEnabled || this.state.isEnabled)){
        //     this.finishTask()
        // }
        console.log("onResume fouthTask this.props.isEnabled = " + this.props.isEnabled)
    }
    constructor(props) {
        super(props)
        this.startTaskTime = -1
        this.state = {
            isFinished: false,
            isReadyStart: false,
            btn: 'Поставить'
        }
        window.fouthTask = this
        this.installUrl = this.props.task.type_os == AppsType.android ? AppsUrls.android : AppsUrls.rustore
        this.type = 2
    }
    componentDidUpdate() {
        if (this.props.isEnabled && !this.state.isReadyStart) {
            this.setState({ isReadyStart: true })
        }
    }
    perfomGoodReviewTop = () => {
        fetch("https://app-coins.ru/api/perfomGoodReviewTop", {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
            body: qs.stringify(this.props.params)
        }).then((response) => response.json())
            .then((responseData) => {
                if (responseData.response == 1) {
                    this.finishTask()
                    this.props.updateMoney(this.props.priceReview)
                } else {
                    this.props.showErrorResponse(responseData.status, "Понятно")
                }
                this.loadCaptcha()
                this.updateLastResponse("perfomReview", responseData)
                console.log("perfomGoodReviewTop" + JSON.stringify(responseData))
            })
    }
    onButtonClick = () => {
        this.startTaskTime = new Date().getTime() / 1000
        var appUrl = this.installUrl + this.props.task.bundle
        this.openUrl(appUrl)
    }
    render() {

        return (
            <div className={this.state.isFinished ? 'finished_task_bg' : this.props.isEnabled || this.state.isEnabled ? 'block' : 'not_ready'}>
                <div className="flex padding_16">
                    <div className="img_icon_container">
                        <img width="40" height="40" className="icon_task_type" src={this.state.isFinished ? check : taskLogo} alt="" />
                    </div>

                    <div className="block padding_left_16">
                        <p className="task_name">Поставьте лайки минимум 5-ти положительным отзывам</p>
                        <div className="flex justify_content">
                            <button onClick={this.onButtonClick}  style={this.state.isFinished ? {marginLeft : '0'} : {}} disabled={(this.state.isFinished || !this.state.isReadyStart) && !(this.props.isEnabled || this.state.isEnabled)}
                                className={this.state.isFinished ? 'finished_btn' : 'task_btn'}>{this.state.btn}</button>
                            <div className="text_task_money flex">+{this.props.priceReview}{getMoneyType(this.props.isUpmob)}</div>
                        </div>

                    </div>

                </div>
                <div className="line"></div>
            </div>

        );


    }
}
export default FouthTask;