/* eslint-disable eqeqeq */
import React from 'react';
import TaskItem from './list_items/TaskItem';
import Loader from './custom_views/insta_custom_views/utils_android/LoaderAndroid';
import { getRequestParams, getGoogleId, getBundle, getStartedOrders } from './custom_views/insta_custom_views/utils_android/CookieHelper';
import refreshIcon from './icons_android/refresh_task_android.svg';
import icon_instarlike_android from './icons_android/icon_instarlike_android.svg';
import ufoIcon from './custom_views/insta_custom_views/insta_icons_tasks/ufo_android.png';
import CustomTaskItem from './list_items/CustomTaskItem';
import { isAppInstalled, showAndroidToast, showReviewDialog, checkPickPhoto } from './custom_views/insta_custom_views/utils_android/AndroidMethods';
import order_type_tiktok from '../pages_android/icons_android/order_type_tiktok.svg'
import order_login from '../pages_android/icons_android/order_login.svg'
import { getHttpParams, dataURLtoFile } from './custom_views/insta_custom_views/utils_android/UrlHelperAndroid';
import { getIsAndroid, getIsDesktop, shitPhoneLowScreen, TasksTypes } from './custom_views/insta_custom_views/utils_android/UtilsAndroid';
import TasksOrdersHeader from './custom_views/TasksOrdersHeader';
import ActiveTasks from './ActiveTasksAndroid';
class Tasks extends React.Component {
    constructor(){
        super()
        this.state = {
            tasks: [],
            serviceTasks: [],
            done : false,
            testImage : undefined,
            instaAccountsTasks : [],
            isShowError : false,
            selectedTasksType : TasksTypes.new_tasks
        }
        this.requestTime = 0
        window.tasks = this
        this.isAndroidDevice = getIsAndroid()
    }
    
    getOrders = () => {
        this.setState({tasks : [], done : false})
        this.requestTime = new Date().getTime() / 1000
        if (!getIsAndroid() && getIsDesktop()){
            this.getCustomOrders()
            return
        }
        var params = getRequestParams()
        console.log("getOrdersToPerfom params = ", params)
        //params.timestamp = this.props.timestamp
        fetch("https://app-coins.ru/api/getOrdersToPerfom", getHttpParams(params)).then((response) => response.json())
            .then((responseData) => {
                var finalTasks = []
                if (responseData.orders && responseData.orders.length > 0){
                    var tasks = responseData.orders
                    for (let index = 0; index < tasks.length; index++) {
                        const element = tasks[index];
                        const isShowTask = getStartedOrders().includes(element._id) ? true : !isAppInstalled(element.bundle)
                        if (isShowTask && !finalTasks.includes(element) && element.icon != null) {
                            if (element.sdk_tasks_full_price > 0){
                                if (getBundle() == "com.photos.findbest" || getBundle() == "com.work.goldenantelope"){
                                    finalTasks.push(element)
                                }
                            } else {
                                finalTasks.push(element)
                            }
                        }
                    }
                    const isShowTasks = this.props.isUpmob ? (this.props.wallet_new != undefined || this.props.count_money_r >= 0.04) : true
                    console.log("getOrdersToPerfom = ", responseData)
                    // console.log("getOrdersToPerfom finalTasks = " + JSON.stringify(finalTasks))
                    // console.log("getOrdersToPerfom isShowTasks = " + isShowTasks)
                    this.setState({
                        tasks: isShowTasks ? finalTasks : [],
                        done: true,
                        isShowError : false
                    })
                    if ((getBundle() == "com.work.goldenantelope" || getBundle() == "com.photos.findbest") && finalTasks.length <= 2){
                        showReviewDialog()
                    }
                    
                } 
                //this.getLoginOrder()
                this.getCustomOrders()
               
            }).catch(() => {
                this.setState({
                    done: true, isShowError : true
                })
                
            });
    }
    
    getLoginOrder = () => {
        var params = getRequestParams()
        fetch("https://app-coins.ru/api/canShowInstaSupportAccounts", getHttpParams(params)).then((response) => response.json())
                .then((responseData) => {
                    const isShowTasks = this.props.wallet_new != undefined || this.props.count_money_r >= 0.04
                    var instaAccountsTasks = []
                    var loginTask = {
                        '_id' : '2',
                        'icon': order_login,
                        'name': 'Регистрация аккаунта',
                        'description': 'Зарегистрируйте аккаунт в Инстаграм',
                        'description_state': 'Зарегистрируйте аккаунт',
                        'count_earning_employer_r' : "10"
                    }
                    if (responseData.canShow && isShowTasks){
                        instaAccountsTasks.push(loginTask)
                        this.setState({instaAccountsTasks : instaAccountsTasks, done : true})
                    }
                    
                    // serviceTasks.push(loginTask)
                    // this.setState({serviceTasks : serviceTasks, done : true})
                    
                })
    }
    getCustomOrders = () => {
        if (this.props.isUpmob){
            var params = getRequestParams()
            //params.timestamp = this.props.timestamp
            fetch("https://app-coins.ru/api/getOrdersToPerfomYoutube", getHttpParams(params)).then((response) => response.json())
                .then((responseData) => {
                    if (responseData.response == 1 || responseData.response == 2){
                    const isShowTasks = this.props.isUpmob ? (this.props.wallet_new != undefined || this.props.count_money_r >= 0.04) : true
                    var serviceTasks = []
                    var serviceTask = {
                        '_id' : '0',
                        'icon': icon_instarlike_android,
                        'name': 'Задания Instarlike',
                        'description': 'Нужно, чтобы у вас был аккаунт в Instagram',
                        'description_state': 'Нужно, чтобы у вас был аккаунт в Instagram',
                        'count_earning_employer_r' : "0.02"
                    }
                    if (isShowTasks && this.props.isUpmob){
                        serviceTasks.push(serviceTask)
                    }
                    
                    var tikTokTask = {
                        '_id' : '1',
                        'icon': order_type_tiktok,
                        'name': 'Задания TikTok',
                        'description': 'Нужно, чтобы у вас был аккаунт в TikTok',
                        'description_state': 'Нужно, чтобы у вас был аккаунт в TikTok',
                        'count_earning_employer_r' : "0.02"
                    }
                    var firstTask = {
                        '_id' : '-9',
                        'icon': "https://app-coins.ru/youtube_icons/order_type_youtube.svg",
                        'name': 'Просмотр видео',
                        'description': 'Посмотрите видео минимум 5 секунд',
                        'description_state': 'Посмотрите видео минимум 5 секунд',
                        'count_earning_employer_r' : "0.03",
                        'url' : 'https://rutube.ru/video/c80a2d14d3a6a33247bf65b1f832b01e/'
                    }
                    //if (isAppInstalled("com.zhiliaoapp.musically") && (getGoogleId() == "111721080232915624716" || getGoogleId() == 111721080232915624716)){
                    
                    // if (isShowTasks && isAppInstalled("com.zhiliaoapp.musically")){
                    //     serviceTasks.push(tikTokTask)
                    // }
                    if (!isShowTasks && this.props.isUpmob){
                        serviceTasks.push(firstTask)
                    }

                    if (isShowTasks){
                        var customOrder = responseData.youtubeOrders
                        for (let index = 0; index < customOrder.length; index++) {
                            const order = customOrder[index];
                            if (order.icon != undefined){
                                if (order.url.includes("tg://resolve?domain=")){
                                    if (isAppInstalled("org.telegram.messenger")){
                                        serviceTasks.push(order)
                                    }
                                } else if(order.url.includes("tiktok.com")){
                                    if (isAppInstalled("com.zhiliaoapp.musically")){
                                        serviceTasks.push(order)
                                    }
                                } else if (order.url.includes("play.google.com")){
                                    var url = new URL(order.url)
                                    var bundle = url.searchParams.get("id")
                                    if (!isAppInstalled(bundle) || bundle == "com.photos.findbest" || bundle == "com.royal.work"){
                                        serviceTasks.push(order)
                                    }
                                } else {
                                    serviceTasks.push(order)
                                }
                            }
                            
                            
                        }
                    }
                    console.log("serviceTasks = ", serviceTasks)
                    this.setState({serviceTasks : serviceTasks, done : true, isShowError : false})
                    
                    }
                })
        } else {
            this.setState({
                serviceTasks: [],
                done: true
            })
        }
    }
 
    
    componentDidMount(){
        console.log("componentDidMount  this.getOrders")
        this.getOrders()
    }
    // componentDidUpdate(prevProps){
    //     if (prevProps.count_money_r != this.props.count_money_r){
    //         this.getOrders()
    //     }
    // }
    updateOrders = () => {
        if (getGoogleId() == "111721080232915624716" || getGoogleId() == "110197615044538411806"){
            //showReviewDialog()
            checkPickPhoto()
            //openAppWithParams('com.vve.instaprogress', 'asd', 'asd')
        } else {
            this.setState({done : false})
            var currentTime = new Date().getTime() / 1000
            if (currentTime - 30 >= this.requestTime) {
                this.requestTime = new Date().getTime() / 1000
                this.getOrders()
            } else {
                setTimeout(this.timeoutCompleted, 1500);
            }
        }        
    }
    handleImage(image){
        this.uploadToServer(image)
    }
    uploadToServer = (imageBase64) => {
        var data = new FormData()
        data.append('file1', dataURLtoFile(imageBase64, 'file.png'))
        fetch("https://upmob.ru/api/uploadFile", {
            method: 'POST',
            mimeType: "multipart/form-data",
            body: data
        }).then((response) => response.json())
        .then((responseData) => {
        })
    }
    
    timeoutCompleted = () => {
        this.getCustomOrders()
        //this.setState({done : true, tasks : []})
    }
    orderError = () => {
        showAndroidToast("Вас кто-то опередил и уже выполнил задание. Попробуйте выбрать другой заказ.")
        this.getOrders()
    }
    render() {
        
        if (!this.state.done){
            return(<Loader/>) 
        } else if (this.state.isShowError){
            return(<div className="flex justify_content pad_left_0">
                    <p className="task_name active_tasks_text">Вы слишком часто перезагружаете страницу, сделайте перерыв на 10 минут и вернитесь в приложение.</p>
        
                </div>)
        } else {
            if ((this.state.tasks && this.state.tasks.length > 0) || this.props.isUpmob){
                return (
                    <div className='tasks_list_container'>
                        {(this.isAndroidDevice && this.props.isUpmob) && <div className="banner_dont_logout">⛔ За будущие перелогины на одном устройстве —{!shitPhoneLowScreen() ? <br/> : " "}будет штраф</div>}
                        <div className="flex justify_content pad_left_0">
                                {/* <p className="task_name active_tasks_text">Новые задания</p> */}
                                <TasksOrdersHeader selectedOrderType={this.state.selectedTasksType} setSelectedOrderType={(type) => this.setState({selectedTasksType : type})}/>
                                {this.state.selectedTasksType == TasksTypes.new_tasks && 
                                    <div onClick={this.updateOrders} className="flex refresh_container hover">
                                        <img className="refresh_icon" src={refreshIcon} alt=""/>
                                    </div>
                                }
        
                        </div>
                        
                        {this.state.selectedTasksType == TasksTypes.active_tasks ? 
                            <ActiveTasks onTaskClicked={this.props.onTaskClicked} info={this.props.info}/>
                        : 
                            <div>
                                {this.state.tasks.length > 0 && this.state.tasks.map((item, index) => (
                                    <TaskItem isUpmob={this.props.isUpmob} onTaskClicked={this.props.onTaskClicked} orderError={this.orderError} task={item} key={index}/>
                                ))}
                                {this.state.instaAccountsTasks.length > 0 && this.state.instaAccountsTasks.map((item, index) => (
                                    <CustomTaskItem count_money_r={this.props.count_money_r} updateMoney={this.props.updateMoney} updateOrders={this.updateOrders} onTaskClicked={this.props.onTaskClicked} instaUsername={this.props.instaUsername} openViewBack={this.props.openViewBack} orderError={this.orderError} task={item} index={index} key={index}/>
                                ))}
                                {this.state.serviceTasks.length > 0 && this.state.serviceTasks.map((item, index) => (
                                    <CustomTaskItem count_money_r={this.props.count_money_r} updateMoney={this.props.updateMoney}  updateOrders={this.updateOrders} onTaskClicked={this.props.onTaskClicked} instaUsername={this.props.instaUsername} openViewBack={this.props.openViewBack} orderError={this.orderError} task={item} index={index} key={index}/>
                                ))}
                            </div>
                        }
                    </div>
                )
             } else if(!this.props.isUpmob){
                return (
                    <div className="empty_tasks_container">
                        <div className="display_block">
                            <img className="ufo_icon" src={ufoIcon} alt=""/>
                            <p className="empty_tasks_text_new">На данный момент<br/>новых заданий нет</p>
                            <button style={{width : '144px'}} onClick={this.updateOrders} className="task_btn update_btn_new">Обновить</button>
                        </div>
                    </div>
                )
            }
        }
         
    }
}
export default Tasks;