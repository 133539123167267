import { getRequestParams } from "../../pages_android/custom_views/insta_custom_views/utils_android/CookieHelper"
import { getHttpParams, getRequestUrl } from "../../pages_android/custom_views/insta_custom_views/utils_android/UrlHelperAndroid"
import { log, onResponse } from "./ApiUtils"

export class UsersApi {
    
    static getMyFriends(onSuccess, onError) {
        const params = getRequestParams()
        fetch(getRequestUrl() + "getMyFriends", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("getMyFriends response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }
    static getMyInfoEmployer(onSuccess, onError) {
        const params = getRequestParams()
        fetch(getRequestUrl() + "getMyInfoEmployer", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            log("getMyInfoEmployer response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }

   
}