/* eslint-disable eqeqeq */
import { Link } from "react-router-dom";
import React from 'react';
import { Pages } from "../../pages_android/custom_views/insta_custom_views/utils_android/UtilsAndroid";
function BottomMenuItem(props) {
    const isSelected = (props.link == "" || props.link == "/") ? (props.link == window.location.pathname || window.location.pathname == "/") : window.location.pathname.includes(props.link)
    console.log("window.location.pathname = ", window.location.pathname)
    console.log("isSelected = ", isSelected)
    console.log("isSelected  props.link = ", props.link)
    const handleOnClick = () => {
        props.setCurrentLink(props.link)
        //history.push(props.link)
    }
    return (
        <Link to={props.link}>
            <div  style={isSelected ? {} : {opacity : 0.4}} onClick={handleOnClick} className="bottom_menu_item hover">
                <div className="left_item_icon">
                    <div style={{height : '20px', width : '20px'}} className="relative">
                        <img alt='' className={"bottom_menu_icon"} src={props.icon}/>
                    </div>
                </div>
                
            
                <p className="bottom_menu_title">{props.title}</p>
            </div>
        </Link>
    );
}

export default BottomMenuItem;

