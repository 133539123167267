/* eslint-disable eqeqeq */
import React from 'react';
import { AppsType } from '../custom_views/insta_custom_views/utils_android/UtilsAndroid';

const ActiveTaskItem = ({ task, onTaskClicked, backView }) => {
    const openTask = () => {
        onTaskClicked(task, 'task', backView);
    };

    return (
        <div className="task_item_container hover" onClick={openTask}>
            <div className="flex task_item_full_width">
                <img className="task_item_icon custom_icons_task_shadow" src={task.icon} alt="" />
                <div className="block justify_content task_item_full_width">
                    <p className="task_item_title task_name_overflow">{task.name}</p>
                    <p className="task_item_type">{task.type_os == AppsType.android ? "Задание в Google Play" : "Задание в RuStore"}</p>
                    {/* <p className='task_item_price'>+{task.price_one_install_employer}₽</p> */}
                </div>
            </div>
            <p className="task_item_money_c">+{task.price_one_install_employer}₽</p>
        </div>
        // <div onClick={openTask}>
        //     <div className="flex justify_content bot_12">
        //         <div className="flex">
        //             <div className="icon_container">
        //                 <img className="tasks_icon" src={task.icon} alt=""/>
        //             </div>
                    
        //             <div className="block justify_content text_container">
        //                 <p className="task_name task_name_overflow">{task.name}</p>
        //                 <p className="task_description">{task.category}</p>
        //             </div>
        //         </div>
        //         <p className="money_text">+{task.price_one_install_employer}₽</p>
        //     </div>
        // </div>
    );
};

export default ActiveTaskItem;