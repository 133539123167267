import React from 'react';

class BaseTask extends React.Component {
   
    
    constructor(props){
        super(props)
       
    }

    isAppInstalled(bundle) {
        if (typeof window.Android !== "undefined" && window.Android !== null) {
            return window.Android.isAppInstalled(bundle);
        } else {
            return false
        }
    }
    checkInstalledNew(bundle) {
        if (typeof window.Android !== "undefined" && window.Android !== null && window.Android.checkInstalledNew !== null && window.Android.checkInstalledNew !== undefined && window.Android.checkInstalledNew !== "undefined") {
            console.log("checkInstalledNew 1")
            return window.Android.checkInstalledNew(bundle);
        } else {
            console.log("checkInstalledNew 2")
            return false
        }
    }
    
    openUrl(url) {
        console.log("openUrl url = ", url)
        if (typeof window.Android !== "undefined" && window.Android !== null) {
            return window.Android.openUrl(url);
        } else {
            return false
        }
    }
    loadCaptcha(){
        console.log("loadCaptha")
    }
    updateLastResponse(method, data){
        console.log("updateLastResponse")
    }
    showAndroidToast(toast) {
        if (typeof window.Android !== "undefined" && window.Android !== null) {
            window.Android.showToast(toast);
        }
    }
    openApp(bundle) {
        // if (getCookie("google_user_id") == ""){

        // } else {
        //     if (typeof window.Android !== "undefined" && window.Android !== null) {
        //         return window.Android.openApp(bundle);
        //     } else {
        //         return false
        //     }
        // }
        if (typeof window.Android !== "undefined" && window.Android !== null) {
            return window.Android.openApp(bundle);
        } else {
            return false
        }
    }
//    fallbackToStore = function() {
//         window.location.replace('https://play.google.com/store/apps/details?id=packgename');
//         };
//         var openApp = function() {
//         window.location.replace('intent://app/SplashScreen#Intent;scheme=app_;package=com.sdf.android.dsds;end');
//         };
//         var triggerAppOpen = function() {
//         openApp();
//         setTimeout(fallbackToStore, 700);
//     };
    componentDidMount(){
        console.log("type = " + this.type)
        var progress = this.props.progress
        if (progress){
            //progress = progress.split(",")
            var element = parseInt(progress[this.type])
            if (element == 1){
                this.finishTask()
            } else if (element == 0) {
                this.setState({isEnabled : true})
            }
        }
    }
    finishTask(){
        this.setState({isFinished : true, btn : "Выполнено"})
        this.props.completedTask()
    }
    
   
   
}
export default BaseTask;

