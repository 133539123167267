import React from 'react';


const ImageDialog = ({ image, onCancel }) => {
    return (
        <div className="error_bg">
            <div onClick={onCancel} className="tooltip_bg_analize"></div>
            <div onClick={onCancel} className="image_dialog">
                <img className="image_dialog_img" src={image} alt=""/>
            </div> 
            {/* <p style={{color : 'white', margin : '16px'}}>{image}</p>  */}
        </div>
    );
}

export default ImageDialog;