/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import { copyId, openUrl } from './custom_views/insta_custom_views/utils_android/AndroidMethods';
import ic_copy_android from '../pages_android/icons_android/ic_copy_android.svg'
import { UsersApi } from '../components_android/api/UsersApi';
import FinishedTasksAndroid from './FinishedTasksAndroid';
import { Link } from 'react-router-dom';
import { getIsDesktop, Pages } from './custom_views/insta_custom_views/utils_android/UtilsAndroid';
const Profile = (props) => {
    const [friendsCount, setFriendsCount] = useState(0)
    useEffect(() => {
        getMyFriends()
    }, [])
    const user = props.user
    if (user.google_user_id == undefined){
        return null
    }
    
    const isDesktop = getIsDesktop()
    const copyMyId = () => {
        copyId(user.google_user_id, "ID скопирован");
    };
    const copyRefText = () => {
        copyId("Хочешь зарабатывать до 1 000 рублей в сутки не выходя из дома? Тогда скачай приложение в Google Play или AppStore и начни зарабатывать прямо сейчас. Первые 2 рубля получишь только за использование этого промокода: " + user._id + " https://appmob.ru", "Ссылка скопирована")
    }
    const getMyFriends = () => {
        UsersApi.getMyFriends((responseData) => {
            setFriendsCount((responseData.countFriends != undefined ? responseData.countFriends : 0))
        }, () => {})
    }
    return (
        <div className="profile_container">
            <div className="profile_container_item">
                <img className="task_item_icon custom_icons_task_shadow" src={user.photo_url} alt="" />
                <div className="block justify_content full_width">
                    <p className="task_item_title task_name_overflow ">{user.nick_name}</p>
                    <div onClick={copyMyId} className="flex justify_content copy_container hover">
                        <p className="task_item_type">ID: {user.google_user_id}</p>
                        <img className="copy_icon" src={ic_copy_android} alt=""/>
                    </div>
                    
                </div>
            </div>
            {props.isUpmob && <div className="profile_container_item">
                <div>
                    <p className="task_name bot_16">Обеспечь себя пассивным доходом. Пригласи друга и получай <strong>15% от его заработка.</strong></p>
                    <div className={friendsCount > 0 ? 'pay_history_container' : 'pay_history_container_empty'}>
                        <p className="task_name refs_title">Всего рефералов: {friendsCount}</p>
                    </div>
                    <div onClick={copyRefText} className="task_btn ref_btn_width">Скопировать реферальную ссылку</div>
                    <div style={{marginTop : '8px', paddingTop : '8px'}} onClick={() => copyId(user._id, "Реферальный промокод cкопирован")} className="flex justify_content copy_container hover">
                        <p className="task_item_type">Реферальный промокод: {user._id}</p>
                        <img className="copy_icon" src={ic_copy_android} alt=""/>
                    </div>
                    <div style={{paddingTop : '8px'}} onClick={() => copyId("https://appmob.ru", "Ссылка на приложение скопирована")} className="flex justify_content copy_container hover">
                        <p className="task_item_type">Ссылка на приложение: https://appmob.ru</p>
                        <img className="copy_icon" src={ic_copy_android} alt=""/>
                    </div>
                   
                </div>
            </div>}
            {isDesktop && 
                <div style={{display : 'block'}} className="profile_container_item">
                    {props.isUpmob && <div><Link className="task_name" to={"/" + Pages.payouts}>Заказать выплату</Link><br/><br/></div>}
                    <div className="task_name" onClick={() => openUrl("https://vk.com/@muchmoneyapp-questions")}>Служба поддержки</div><br/>
                    <div className="task_name" onClick={() => openUrl("https://vk.com/@muchmoneyapp-pravila")}>Ответы на часто задаваемые вопросы</div>
                </div>
            }
            <FinishedTasksAndroid onTaskClicked={props.onTaskClicked} info={user}/>
        </div>
    );
};

export default Profile;