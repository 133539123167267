/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import ActiveTaskItem from './list_items/ActiveTaskItem';
import { getRemovedOrders, getRequestParams } from './custom_views/insta_custom_views/utils_android/CookieHelper'
import Loader from './custom_views/insta_custom_views/utils_android/LoaderAndroid';
import ufoIcon from './custom_views/insta_custom_views/insta_icons_tasks/ufo_android.png';
import CustomTaskItem from './list_items/CustomTaskItem';
import { TasksApi } from '../components_android/api/TasksApi';
import moment from 'moment';
import { getHttpParams } from './custom_views/insta_custom_views/utils_android/UrlHelperAndroid';

const ActiveTasks = (props) => {
    const [done, setDone] = useState(false)
    const [allTasks, setAllTasks] = useState([])

    useEffect(() => {
        getMyActiveYoutube();
        //getMyActiveYoutube2()
    }, []);

    const getActiveTasks = () => {
        const finalTasks = [];
        const removedOrders = getRemovedOrders();

        if (props.info?.progress_orders) {
            const tasks = props.info.progress_orders;
            for (let index = 0; index < tasks.length; index++) {
                const element = tasks[index];
                if (!removedOrders.includes(element.bundle)) {
                    element.category = "Активно";
                    if (element.progressArraySDK?.length > 0 && element.progressArraySDK.includes(0)) {
                        finalTasks.push(element);
                    } else if (element.progressArray.includes(0)) {
                        finalTasks.push(element);
                    }
                }
            }
            return finalTasks.reverse();
        }
        return [];
    };
    // const getMyActiveYoutube2 = () => {
    //     var params = getRequestParams()
    //     console.log("getMyActiveYoutube")
    //     fetch("https://app-coins.ru/api/getMyActiveYoutube", getHttpParams(params)).then((response) => response.json())
    //     .then((responseData) => {
    //         if (responseData.response == 1 && responseData.proof_orders_youtube && responseData.proof_orders_youtube.length > 0){
    //             var activeTasks = []
    //             responseData.proof_orders_youtube.forEach(task => {
    //                 if ((task.state != 1 && task.state != -1) && task.order != null){
    //                     activeTasks.push(task)
    //                 }
    //             });

    //         }
    //         console.log("getMyActiveYoutube responseData = ", responseData)
    //     })
    // }
    const getMyActiveYoutube = () => {

        TasksApi.getMyActiveYoutube((responseData) => {
            if (responseData.response == 1 && responseData.proof_orders_youtube?.length > 0) {
                var activeTasks = responseData.proof_orders_youtube.filter(
                    task => task.state != 1 && task.state != -1 && task.order != null
                );
                activeTasks.forEach(element => {
                    element.is_universal = true
                });
                setTasks(activeTasks)
            }
            console.log("getMyActiveYoutube success = ", responseData)
            setDone(true);
        }, (responseData) => {
            setTasks([])
            console.log("getMyActiveYoutube error = ", responseData)
            setDone(true);
        })

    };
    const setTasks = (activeTasks) => {
        var tasks = getActiveTasks();
        tasks.push(...activeTasks)
        const now = moment()
        tasks = tasks.filter(t => {
            const days = now.diff(moment(t.modified), "days")
            return days < 10
        })
        tasks.sort(function (a, b) {
            return new Date(b.modified) - new Date(a.modified);
        })
        setAllTasks(tasks);
    }


    if (!done) {
        return <Loader bg_style={{ position: 'relative', marginTop: 'calc(100vh/2 - 100px)' }} />;
    }

    if (allTasks.length > 0) {
        return (
            <div>
                {allTasks.map((item, index) => (
                    <div key={index}>
                        {item.is_universal ?
                            <CustomTaskItem
                                onTaskClicked={props.onTaskClicked}
                                correction={item.correction}
                                isActive={true}
                                proof_order_id={item._id}
                                orderState={item.state}
                                proof_text={item.proof_text}
                                task={item.order}
                                index={index}
                                key={index}
                                description_state={item.state == 1 ? "<p style='color : #2DB789'>Одобрено</p>" :
                                    (item.state == 2 ? "<p style='color : #E17373'>Нужно доработать</p>" :
                                        (item.state == -1 ? "<p style='color : #E17373'>Отклонено</p>" :
                                            (item.state == 0 ? "<p>На проверке</p>" :
                                                (item.state == 3 ? "<p>Выполняется</p>" : undefined))))}
                            />
                            :
                            <ActiveTaskItem
                                backView="active"
                                onTaskClicked={props.onTaskClicked}
                                isActive={true}
                                task={item}
                                key={index}
                            />
                        }
                    </div>

                ))}

            </div>
        );
    }

    return (
        <div className="empty_tasks_container">
            <div className="display_block">
                <img className="ufo_icon" src={ufoIcon} alt="" />
                <p className="empty_tasks_text_new">На данный момент<br />активных заданий нет</p>
            </div>
        </div>
    );
};

export default ActiveTasks;
