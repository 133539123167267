/* eslint-disable eqeqeq */
import React from 'react';
import taskLogo from "./tasks_icons_android/circle_download_android.svg";
import check from "./tasks_icons_android/check.svg";
import BaseTask from './BaseTaskAndroid';
import qs from 'querystring'
import { getRequestParams } from './insta_custom_views/utils_android/CookieHelper';
import LoaderSearch from './insta_custom_views/LoaderSearch';
import { copyId } from './insta_custom_views/utils_android/AndroidMethods';
import { AppsSearchUrls, AppsType, AppsUrls } from './insta_custom_views/utils_android/UtilsAndroid';

var taskName = "<-- ищите по иконке"
class FirstTask extends BaseTask {
    finishTask(){
        this.setState({isFinished : true, btn : "Выполнено"})
        this.props.completedTask()
    }
   onResume(){
       if (!this.state.isFinished && this.startTaskTime != -1){
            var currentTime = new Date().getTime() / 1000
            if (currentTime - 2 >= this.startTaskTime) {
                var isInstalledLastVersion = this.isAppInstalled(this.props.task.bundle)
                if (isInstalledLastVersion){
                    this.finishTask()
                } else {
                    this.startTaskTime = -1
                    this.props.showErrorResponse("Установка приложения не была завершена. Внимательно проверьте иконку и название приложения.", "Продолжить", this.onButtonClick)
                }
            }
        }
    console.log("onResume FirstTask")
   }
    constructor(props){
        super(props)
        this.state = {
            isFinished : (this.props.progress && this.props.progress[0] == 1),
            btn : "Открыть",
            appPosition : 0,
            isLoading : false,
            taskWithoutSearchRequest : false,
            searchWord : ""
        }
        window.firstTask = this;
        this.installUrl = "https://play.google.com/store/apps/details?id="
        this.searchUrl = "https://play.google.com/store/search?q="
        this.type = 0
        this.startTaskTime = -1
        this.searchWord = ""

        this.currentSearchIndex = 0
        
    }
    componentDidMount(){
        if(this.props.task.search_request != undefined){
            if(this.props.task.search_request.indexOf(",") != -1){
                var arrayRequests = this.props.task.search_request.split(",")
                console.log("shuffleArray prevArray = " + arrayRequests)
                this.shuffleArray(arrayRequests) 
                console.log("shuffleArray afterArray = " + arrayRequests)
                this.searchWord = arrayRequests[this.currentSearchIndex].trim();
                console.log("shuffleArray this.searchWord = " + this.searchWord)
            } else {
                this.searchWord = this.props.task.search_request
                console.log("this.searchWord false = " + this.searchWord)
            }
            // if(!this.searchWord.trim()){
            //     this.props.task.search_request = undefined;
            // }
            if (this.searchWord != undefined && (!this.props.progress || this.props.progress[0] != 1)){
                this.getAppPosition(this.searchWord)
            }
            this.setState({taskWithoutSearchRequest : false, searchWord : this.searchWord})
        } else {
            this.setState({taskWithoutSearchRequest : true, searchWord : ""})
        }
        
    }
    shuffleArray = (array) => {
        
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
    }
    getAppPosition = (text) =>{
        this.setState({isLoading : true})
        const order = this.props.task
        var params = getRequestParams()
            params.bundle = order.bundle
            params.query = text
            params.country = "RU"
            params.type_os = order.type_os
        fetch("https://app-coins.ru/api/searchApp", {
            method: 'POST',
            headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
            body: qs.stringify(params)
        }).then((response) => response.json())
        .then((responseData) => {
            this.setState({
                appPosition: responseData.info, isLoading : false
            })
            this.props.setAppPosition(responseData.info, text)
            console.log("getPriceConfig" + JSON.stringify(responseData))
        })
    }

    onButtonClick = () => {
        this.startTaskTime = new Date().getTime() / 1000
        var type = 0
        var order = this.props.task
        if (order.search_request === "" || order.search_request === undefined || order.search_request == "undefined") {
            type = 0
        } else {
            type = 1
        }

        var url = ""
        if (type == 0) {
            url = order.type_os == AppsType.android ? (AppsUrls.android + order.bundle + "&hl=ru") : (AppsUrls.rustore + order.bundle)
        } else {
            url = order.type_os == AppsType.android ? ("https://play.google.com/store") : AppsSearchUrls.rustore
        }
        if (order.track_url && order.track_url != "") {
            url = order.track_url
        }
       
        console.log("url = " + url);
        this.openUrl(url)
    }
    copySearchWord = () => {
        copyId(this.state.searchWord, "Поисковый запрос скопирован")
    }
    openGP = () => {
        this.startTaskTime = new Date().getTime() / 1000
        this.openUrl("https://play.google.com/store")
    }
    render(){
      
            return (
                <div className={this.state.isFinished ? 'finished_task_bg' : 'block'}>
                    <div className="flex padding_16">
                        <div className="img_icon_container">
                            <img width="40" height="40" className="icon_task_type" src={this.state.isFinished ? check : taskLogo} alt=""/>
                        </div>
                        
                        <div className="block padding_left_16">
                            {!this.state.taskWithoutSearchRequest && <div className="block">
                                <p className="task_name">Скопируйте поисковый запрос</p>
                                <p style={{fontWeight : 'bold'}} className="task_name">{this.state.searchWord}</p>
                                <button onClick={this.copySearchWord} className={this.state.isFinished ? 'finished_btn' : 'task_btn'} style={{margin  : this.state.isFinished ? '6px 0 16px -8px' : '6px 0 16px 0'}}>Копировать запрос</button>
                                <p className="task_name">Откройте приложение в <b>{this.props.task.type_os == AppsType.android ? "Google Play" : "RuStore"}</b> и вставьте запрос в поисковую строку.</p>
                                <p className="task_name">Найдите приложение по иконке и установите его.</p>
                            </div>}
                            
                            <div className="flex">
                                <img style={{marginBottom : '20px'}} width="72" height="72" className="tasks_icon left_0 custom_icons_task_shadow"
                                    src={this.props.task.icon.includes("http") ? this.props.task.icon : "https://" + this.props.task.icon} alt=""/>
                                <div className="block task_icon_container">
                                    {/* <p className="task_name">{taskName}</p> */}
                                    {this.state.taskWithoutSearchRequest ? <p>Установите приложение по прямой ссылке</p> : 
                                    this.state.isLoading ? <LoaderSearch /> : 
                                    <div>
                                        <p className="search_pos">Позиция в поиске:</p>
                                        <p style={{fontWeight : 'bold', fontSize : '16px'}} className="search_pos">{this.state.appPosition >= 300 ? ">30, ищите вручную" : this.state.appPosition != 0 ? this.state.appPosition : "Не найдено, попробуйте перезайти в заказ."}</p>
                                    </div>}
                                    
                                </div>
                            </div>
                            <button onClick={this.onButtonClick} disabled={this.state.isFinished} style={{padding : '10px 24px', margin : this.state.isFinished ? '0 0 0 -24px' : '0 0 16px 0', width : 'auto'}}
                                className={this.state.isFinished ? 'finished_btn' : 'task_btn'}>{this.state.isFinished ? "Выполнено" : this.props.task.type_os == AppsType.android ? "Открыть Google Play" : "Открыть RuStore"}</button>
                        </div>

                    </div>
                    <div className="line"></div>
                </div>
            ); 
        
        
    }
}
export default FirstTask;