import { getRequestParams } from "../../pages_android/custom_views/insta_custom_views/utils_android/CookieHelper"
import { getHttpParams, getRequestUrl } from "../../pages_android/custom_views/insta_custom_views/utils_android/UrlHelperAndroid"
import { log, onResponse } from "./ApiUtils"

export class TasksApi {
    
    static getMyActiveYoutube(onSuccess, onError) {
        const params = getRequestParams()
        console.log("getMyActiveYoutube params = ", params)
        fetch(getRequestUrl() + "getMyActiveYoutube", getHttpParams(params)).then((response) => response.json())
        .then((responseData) => {
            // log("getMyActiveYoutube response = ", responseData)
            onResponse(responseData, onSuccess, onError)
        })
    }

   
}