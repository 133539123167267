/* eslint-disable eqeqeq */
import React from 'react';
import { Link } from 'react-router-dom';
import { Pages } from './custom_views/insta_custom_views/utils_android/UtilsAndroid';
import { openUrl } from './custom_views/insta_custom_views/utils_android/AndroidMethods';

const More = (props) => {
    
    return (
        <div className="profile_container">
            <div style={{display : 'block'}} className="profile_container_item">
               {props.isUpmob && <div><Link className="task_name" to={"/" + Pages.payouts}>Заказать выплату</Link><br/><br/></div>}
               <div className="task_name" onClick={() => openUrl("https://vk.com/@muchmoneyapp-questions")}>Служба поддержки</div><br/>
               <div className="task_name" onClick={() => openUrl("https://vk.com/@muchmoneyapp-pravila")}>Ответы на часто задаваемые вопросы</div>
            </div>
           
        </div>
    );
};

export default More;