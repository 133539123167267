/* eslint-disable eqeqeq */
import React, { useState, useEffect, useCallback } from 'react';
import ReactDOM from "react-dom/client"
import Navbar from './components_android/NavbarAndroid'
import Tasks from './pages_android/TasksAndroid'
import ActiveTasks from './pages_android/ActiveTasksAndroid'
import FinishedTasks from './pages_android/FinishedTasksAndroid'
import Task from './pages_android/TaskAndroid'
import './css_android/style_android.css';
import { ReCaptcha } from 'react-recaptcha-v3'
import Referals from './pages_android/ReferalsAndroid';
import Payouts from './pages_android/PayoutsAndroid';
import GetPayout from './pages_android/GetPayoutAndroid';
import Support from './pages_android/SupportAndroid';
import { getUrlParameter, getHttpParams } from './pages_android/custom_views/insta_custom_views/utils_android/UrlHelperAndroid';
import {saveRegistrCookie, getGoogleId, getRequestParams, getIsUpmobKey, isShowClose, saveApiKey, setCookie, saveBundle} from './pages_android/custom_views/insta_custom_views/utils_android/CookieHelper'
import Loader from './pages_android/custom_views/insta_custom_views/utils_android/LoaderAndroid';
import { registrationFailed, showAndroidToast } from './pages_android/custom_views/insta_custom_views/utils_android/AndroidMethods';
import AuthInsta from './pages_android/AuthInstaAndroid';
import EarnInsta from './pages_android/EarnInstaAndroid';
import CustomTask from './pages_android/CustomTask';
import EarnTikTok from './pages_android/EarnTikTok';
import { YMInitializer } from 'react-yandex-metrika';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import { isShowBottomMenu, Pages } from './pages_android/custom_views/insta_custom_views/utils_android/UtilsAndroid';
import BottomMenu from './components_android/BottomMenu';
import Profile from './pages_android/ProfileAndroid';
import { UsersApi } from './components_android/api/UsersApi';
import More from './pages_android/MoreAndroid';

window.$moneyCount = 0.0;

function Application() {
    // useEffect(() => {
    //     if (window.location.pathname == "/" || window.location.pathname == ""){
    //         window.location.href = Pages.tasks
    //     }
    // }, []);
    const getInitialState = () => {
        const hashId = !getGoogleId("google_user_id");
        const hasApiKey = getUrlParameter("api_key");
        const api_key = getUrlParameter("api_key");
        const isUpmob = hasApiKey ? ["upmob", "lexus", "QWT", "QWT2"].includes(api_key) : true;

        if (api_key) {
            saveApiKey(api_key);
        }

        return {
            count_money_r: 0.0,
            nick_name: "",
            photo_url: "",
            email: "",
            google_user_id: "",
            info: {},
            done: false,
            timestamp: "",
            isUpmob: hashId ? isUpmob : getIsUpmobKey(),
            // isUpmob: false,
            ref_id: "",
            isClose: hashId ? (hasApiKey ? getUrlParameter("api_key") : false) : isShowClose(),
            activeView: 'tasks',
            currentTask: {},
            backView: undefined,
            isMenuOpened: false,
            instagram_user_name: "",
            isLoadCaptcha: false,
            isNeedInstaCaptcha: false,
            isRegistered: !hashId
        };
    };

    const [state, setState] = useState(getInitialState());
    const navigate = useNavigate();

    const updateMoney = useCallback((money) => {
        setState(prev => ({
            ...prev,
            count_money_r: prev.count_money_r + parseFloat(money)
        }));
    }, []);

    const setMoney = useCallback((money) => {
        setState(prev => ({ ...prev, count_money_r: money }));
    }, []);

    const onTaskClicked = useCallback((task, path) => {
        setState(prev => ({
            ...prev,
            currentTask: task
        }));
        navigate(path + "?task_id=" + (task.order_id ? task.order_id : task._id));
    }, [navigate]);

    const toogleMenu = useCallback(() => {
        setState(prev => ({
            ...prev,
            isMenuOpened: !prev.isMenuOpened
        }));
    }, []);

    const onBack = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    const onMenuClicked = useCallback((path) => {
        setState(prev => ({
            ...prev,
            isMenuOpened: false
        }));
        navigate(path);
    }, [navigate]);

    const openViewBack = useCallback((path) => {
        navigate(path);
    }, [navigate]);

    const onPayoutClicked = useCallback(() => {
        navigate('/payout');
    }, [navigate]);

    const verifyCallback = useCallback((response) => {
        setState(prev => ({ ...prev, isLoadCaptcha: false }));
    }, []);

    const getMyInfo = useCallback(() => {
        setState(prev => ({ ...prev, done: false }));
        UsersApi.getMyInfoEmployer((responseData) => {
            if (responseData.response > 0) {
                    setState(prev => ({
                        ...prev,
                        isRegistered: true,
                        count_money_r: responseData.employer.count_money_r,
                        nick_name: responseData.employer.nick_name,
                        photo_url: responseData.employer.photo_url,
                        email: responseData.employer.email,
                        google_user_id: responseData.employer.google_user_id,
                        info: responseData.employer,
                        ref_id: responseData.employer.ref_id,
                        wallet_new: responseData.employer.wallet_new,
                        min_transfer: responseData.employer.min_transfer,
                        instagram_user_name: responseData.employer.instagram_user_name,
                        done: true
                    }));
            }
        }, () => {})       
    }, []);

    const registerUser = useCallback(() => {
        var params = getRequestParams()
        var device_id = getUrlParameter('device_id')
        var token_google = getUrlParameter('token_google')
        var uniq_user_id = getUrlParameter('uniq_user_id')
        var api_key = getUrlParameter('api_key')
        var bundle = getUrlParameter('bundle')
        if (bundle != "" && bundle != undefined && bundle != null){
            saveBundle(bundle)
        }
        params.device_id = device_id
        params.token_google = token_google
        params.easy_money_user_id = uniq_user_id
        params.api_key = api_key
        params.bundle = bundle
        if (api_key == "lexus"){
            params.promo_id = "945113"
        } else if (api_key == "QWT"){
            params.promo_id = "2554224"
        } else if (api_key == "QWT2"){
            params.promo_id = "2719566"
        }
        fetch("https://app-coins.ru/api/registerEmployer", getHttpParams(params))
            .then((response) => response.json())
            .then((responseData) => {
                console.log("registerEmployer = ", responseData)
                if (responseData.status == "OK") {
                    saveRegistrCookie(responseData, device_id, uniq_user_id, api_key);
                    getMyInfo();
                } else {
                    //showAndroidToast("registrationFailed = " + JSON.stringify(responseData.status))
                    registrationFailed();
                }
                // if (responseData.response < 0){
                //     registrationFailed();
                // }
            });
    }, [getMyInfo]);

    useEffect(() => {
        console.log("componentDidMount index");
        
        if (getUrlParameter("google_user_id")) {
            setCookie("google_user_id", getUrlParameter("google_user_id"));
            setCookie("device_id", getUrlParameter("device_id"));
            setCookie("api_key", getUrlParameter("api_key"));
            getMyInfo();
            return;
        }
        if (!getGoogleId("google_user_id")) {
            registerUser();
        } else {
            getMyInfo();
        }
    }, []);

    if (!state.done) {
        return <Loader />;
    }

    return (
        <div>
            {(window.location.hostname !== 'localhost') && 
                <YMInitializer 
                    accounts={[86575405]} 
                    options={{webvisor: true, clickmap:true, trackLinks:true, accurateTrackBounce:true}} 
                />
            }
            {state.isLoadCaptcha && 
                <ReCaptcha
                    sitekey="6LdFRuEUAAAAAGBfnPHHxCvPSn8bl5ISdZSLiu7_"
                    action='homepage'
                    verifyCallback={verifyCallback}
                />
            }
            <div className="app">
                {/* <MaterialMenuAndroid 
                    activeView={state.activeView} 
                    toogleMenu={toogleMenu} 
                    isMenuOpened={state.isMenuOpened} 
                    onMenuClicked={onMenuClicked} 
                    isUpmob={state.isUpmob} 
                    count_money_r={state.count_money_r} 
                    name={state.nick_name} 
                    photo={state.photo_url} 
                    email={state.email} 
                    google_user_id={state.google_user_id}
                /> */}
                <div className="main">
                    <Navbar 
                        toogleMenu={toogleMenu} 
                        onBack={onBack} 
                        isClose={state.isClose} 
                        isUpmob={state.isUpmob} 
                        count_money_r={state.count_money_r}
                    />
                    <section className='main_container_center'>
                        <Routes>
                            <Route 
                                path={`/${Pages.tasks}`}
                                element={
                                    state.isRegistered && (
                                        <Tasks 
                                            updateMoney={updateMoney} 
                                            wallet_new={state.wallet_new} 
                                            count_money_r={state.count_money_r} 
                                            readyToTasks={state.isRegistered} 
                                            instaUsername={state.instagram_user_name} 
                                            openViewBack={openViewBack} 
                                            onTaskClicked={onTaskClicked} 
                                            isUpmob={state.isUpmob}
                                            info={state.info}
                                        />
                                    )
                                } 
                            />

                            <Route 
                                path={`/${Pages.task}`} 
                                element={
                                    <Task 
                                        onBack={onBack} 
                                        task={state.currentTask} 
                                        updateMoney={updateMoney}
                                        isUpmob={state.isUpmob}
                                    />
                                } 
                            />

                            <Route 
                                path={`/${Pages.active}`} 
                                element={
                                    <ActiveTasks 
                                        onTaskClicked={onTaskClicked} 
                                        info={state.info}
                                    />
                                } 
                            />

                            <Route 
                                path={`/${Pages.finished}`} 
                                element={
                                    <FinishedTasks 
                                        onTaskClicked={onTaskClicked} 
                                        info={state.info}
                                    />
                                } 
                            />

                            <Route 
                                path={`/${Pages.referals}`} 
                                element={<Referals ref_id={state.ref_id}/>} 
                            />

                            <Route 
                                path={`/${Pages.payouts}`} 
                                element={<Payouts onPayoutClicked={onPayoutClicked}/>} 
                            />

                            <Route 
                                path={`/${Pages.payout}`} 
                                element={
                                    <GetPayout 
                                        wallet_new={state.wallet_new} 
                                        updateMoney={updateMoney} 
                                        min_transfer={state.min_transfer} 
                                        count_money_r={state.count_money_r}
                                    />
                                } 
                            />

                            <Route 
                                path={`/${Pages.support}`} 
                                element={
                                    <Support 
                                        isUpmob={state.isUpmob} 
                                        google_user_id={state.google_user_id}
                                    />
                                } 
                            />

                            <Route 
                                path={`/${Pages.auth_insta}`} 
                                element={<AuthInsta openViewBack={openViewBack}/>} 
                            />

                            <Route 
                                path={`/${Pages.earn_insta}`} 
                                element={
                                    <EarnInsta 
                                        isNeedInstaCaptcha={state.isNeedInstaCaptcha}
                                        openViewBack={openViewBack} 
                                        setMoney={setMoney}
                                    />
                                } 
                            />

                            <Route 
                                path={`/${Pages.tiktok_task}`} 
                                element={<EarnTikTok setMoney={setMoney}/>} 
                            />

                            <Route 
                                path={`/${Pages.custom_task}`} 
                                element={
                                    <CustomTask 
                                        task={state.currentTask} 
                                        openViewBack={openViewBack} 
                                        updateMoney={updateMoney}
                                    />
                                } 
                            />
                            <Route 
                                path={`/${Pages.profile}`} 
                                element={
                                    <Profile 
                                        isUpmob={state.isUpmob}
                                        user={state.info} 
                                        openViewBack={openViewBack} 
                                        updateMoney={updateMoney}
                                        onTaskClicked={onTaskClicked}
                                    />
                                } 
                            />
                            <Route 
                                path={`/${Pages.more}`} 
                                element={
                                    <More 
                                        isUpmob={state.isUpmob}
                                        user={state.info} 
                                        openViewBack={openViewBack} 
                                        updateMoney={updateMoney}
                                        onTaskClicked={onTaskClicked}
                                    />
                                } 
                            />
                        </Routes>
                        {isShowBottomMenu() && <BottomMenu/>}
                    </section>
                </div>
            </div>
        </div>
    );
}

function AppWrapper() {
    return (
        <BrowserRouter>
            <Application />
        </BrowserRouter>
    );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<AppWrapper />);

// Глобальные обработчики
window.onResume = () => {
    console.log("onResume index");
    const tasks = [
        window.firstTask,
        window.secondTask,
        window.thirdTask,
        window.fouthTask,
        window.fifthTask,
        window.extraView,
        window.photoTask,
        window.extraFollow,
        window.extraComment,
        window.customTask,
        window.sdkTask
    ];

    tasks.forEach(task => {
        if (task !== undefined) {
            task.onResume();
        }
    });
};

window.handleImage = (image) => {
    if (window.tasks !== undefined) {
        window.tasks.handleImage(image);
    }
};

export default AppWrapper;


