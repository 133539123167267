import more from '../icons/menu/more.svg'
import tasks from '../icons/menu/tasks.svg'
import profile from '../icons/menu/profile.svg'
import { useState, useEffect } from 'react';
import { Pages } from '../pages_android/custom_views/insta_custom_views/utils_android/UtilsAndroid';
import BottomMenuItem from './custom_views/BottomMenuItem';

function BottomMenu(props) {
    const [currentLink, setCurrentLink] = useState(Pages.experts)
    useEffect(() => {
        setCurrentLink(Pages.tasks)
    }, [])
    const user = props.user
  
    return (
        <div className="bottom_menu_container">
            <BottomMenuItem setCurrentLink={setCurrentLink} link={Pages.tasks} title="Задания" icon={tasks}/>
            <BottomMenuItem setCurrentLink={setCurrentLink} link={Pages.profile} title="Профиль" icon={profile}/>
            <BottomMenuItem setCurrentLink={setCurrentLink} link={Pages.more} title="Ещё" icon={more}/>
        </div>
    );
}

export default BottomMenu;

