/* eslint-disable eqeqeq */
export const AppsType = {
    android: "android",
    rustore: "rustore",
    ios: "ios",
}
export const getAppUrl = (type_os, bundle) => {
    var url = ""
    if (type_os == AppsType.android){
        url = AppsUrls.android + bundle
    } else if (type_os == AppsType.rustore){
        url = AppsUrls.rustore + bundle
    } else {
        url = AppsUrls.ios + bundle
    }
    return url
}
export const getIsDesktop = () => {
    return window.innerWidth >= 960
}
export const shitPhoneLowScreen = () => {
    return window.innerWidth <= 340
}
export const getIsAndroid = () => {
    var ua = navigator.userAgent.toLowerCase();
    return ua.indexOf("android") > -1;
}
export const getSmoozieMultiplier = () => {
    return 25
}
export const getMoneyType = (isUpmob) => {
    return isUpmob ? "₽" : "🍊"
}
export const isShowBackBtn = () => {
    return (window.location.pathname == "" || window.location.pathname == "/" || window.location.pathname.includes(Pages.tasks) || window.location.pathname.includes(Pages.active) || window.location.pathname.includes(Pages.finished) || window.location.pathname.includes(Pages.profile) || window.location.pathname.includes(Pages.more))
}
export const isShowBottomMenu = () => {
    const isShow = !getIsDesktop() && isShowBackBtn()

    // console.log("isShowBottomMenu = ", isShow)
    // console.log("isShowBottomMenu window.location.pathname = ", window.location.pathname)
    return isShow
}
export const getSearchRequestAppUrl = (type_os, bundle, search_request) => {
    var url = ""
    if (type_os == AppsType.android){
        url = AppsSearchUrls.android + search_request + '&c=apps&hl=ru'
    } else if (type_os == AppsType.rustore){
        url = AppsSearchUrls.rustore + search_request
    } else {
        if (window.innerWidth >= 769) {
            url = AppsUrls.ios + bundle
        } else {
            url = AppsSearchUrls.ios + search_request
        }
    }
    return url
}

export const AppsUrls = {
    android : "https://play.google.com/store/apps/details?id=",
    rustore : "https://www.rustore.ru/catalog/app/",
    ios : "https://itunes.apple.com/ru/app/id"
}
export const AppsSearchUrls = {
    android : "https://play.google.com/store/search?q=",
    rustore : "https://www.rustore.ru/catalog/search?query=",
    ios : "https://search.itunes.apple.com/WebObjects/MZSearch.woa/wa/search?media=software&term="
}

export const TasksTypes = {
    new_tasks : 'new',
    active_tasks : 'active',
}
export const Pages = {
    tasks : 'tasks',
    task : 'task',
    active : 'active',
    finished : 'finished',
    referals : 'referals',
    payouts : 'payouts',
    payout : 'payout',
    support : 'support',
    auth_insta : 'auth_insta',
    earn_insta : 'earn_insta',
    tiktok_task : 'tiktok_task',
    custom_task : 'custom_task',
    profile : 'profile',
    more : 'more',
}
export const Panels = {
    tasks_panel : "tasks_panel",
    task_panel : "task_panel",
    active_panel : "active_panel",
    finished_panel : "finished_panel",
    referals_panel : "referals_panel",
    payouts_panel : "payouts_panel",
    payout_panel : "payout_panel",
    support_panel : "support_panel",
    auth_insta_panel : "auth_insta_panel",
    earn_insta_panel : "earn_insta_panel",
    tiktok_task_panel : "tiktok_task_panel",
    custom_task_panel : "custom_task_panel",
 
}