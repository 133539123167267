/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import { getRequestParams } from './custom_views/insta_custom_views/utils_android/CookieHelper'
import moment from 'moment'
import PayoutItem from './list_items/PayoutItemAndroid';
import { getHttpParams } from './custom_views/insta_custom_views/utils_android/UrlHelperAndroid';
import Loader from './custom_views/insta_custom_views/utils_android/LoaderAndroid';
import ufoIcon from './custom_views/insta_custom_views/insta_icons_tasks/ufo_android.png';

const Payouts = ({ onPayoutClicked }) => {
    const [payouts, setPayouts] = useState([]);
    const [done, setDone] = useState(false);

    const getPayHistory = async (params) => {
        try {
            const response = await fetch("https://app-coins.ru/api/getPayHistory", getHttpParams(params));
            const responseData = await response.json();
            
            if (responseData.response === 1 || responseData.response === 2) {
                const processedPayouts = responseData.data.map(payout => {
                    const timestamp = moment.unix(payout.time / 1000);
                    const formattedTime = timestamp.format("DD.MM.YYYY");
                    
                    const statusMap = {
                        success: { text: "успешно", type: 0 },
                        error: { text: "ошибка", type: 1 },
                        not_completed: { text: "в процессе", type: 2 }
                    };
                    
                    const { text: status_text = "", type: status_type = 0 } = statusMap[payout.status] || {};

                    return {
                        ...payout,
                        time: formattedTime,
                        status_text,
                        status_type
                    };
                });

                console.log("payouts = " + JSON.stringify(processedPayouts));
                setPayouts(processedPayouts.length > 0 ? processedPayouts.reverse() : []);
                setDone(true);
            }
            console.log("getPayHistory" + JSON.stringify(responseData));
        } catch (error) {
            console.error("Ошибка при получении истории платежей:", error);
            setPayouts([]);
            setDone(true);
        }
    };

    useEffect(() => {
        const params = getRequestParams();
        console.log("params = " + JSON.stringify(params));
        getPayHistory(params);
    }, []);

    const getPayout = () => {
        onPayoutClicked();
    };

    return (
        <div className="block padding_16">
            <div onClick={getPayout} className="task_btn ref_btn_width">
                Заказать выплату
            </div>
            {!done ? 
                <Loader bg_style={{ position: 'relative', marginTop: 'calc(100vh/2 - 100px)' }} />
            :
            payouts.length == 0 ?
                <div className="empty_tasks_container">
                    <div className="display_block">
                        <img className="ufo_icon" src={ufoIcon} alt="" />
                        <p className="empty_tasks_text_new">Выплаты отсутствуют</p>
                    </div>
                </div>
            :
                <div className={payouts.length > 0 ? 'pay_history_container' : 'pay_history_container_empty'}>
                    <p className="task_name refs_title">История выплат</p>
                    {payouts.map((item, index) => (
                        <PayoutItem payout={item} key={index} />
                    ))}
                </div>}
        </div>
    );
};

export default Payouts;