/* eslint-disable eqeqeq */
import React from 'react';
import { getIsDesktop, TasksTypes } from './insta_custom_views/utils_android/UtilsAndroid';

const TasksOrdersHeader = ({ selectedOrderType, setSelectedOrderType }) => {
    const isNewTasks = selectedOrderType == TasksTypes.new_tasks
    const isDesktop = getIsDesktop()
    return (
        <div style={{marginBottom : '16px'}}>
            <div className="flex sdk_controlls">
                <p onClick={() => setSelectedOrderType(TasksTypes.new_tasks)} style={isNewTasks ? {color : '#1696EC'} : {}} className={isNewTasks ? "sdk_title_controll sdk_title_controll_margin hover" : "sdk_title_controll sdk_title_controll_margin sdk_title_controll_enabled hover"}>Новые задания</p>
                <p onClick={() => setSelectedOrderType(TasksTypes.active_tasks)} style={{color : isNewTasks ? "" : "#1696EC" , marginRight: '8px' }} className={"sdk_title_controll hover"}>Активные задания</p>

            </div>
            <div className="toolbar_line_container admin_controll_line">
                <div style={isNewTasks ? { marginLeft: isDesktop ? 0 : '16px', width: '102px' } : { marginLeft: isDesktop ? '116px' : '130px', width: '122px' }} className="toolbar_line" />
            </div>
        </div>

    )

}
export default TasksOrdersHeader

