import React from 'react';
import TaskItem from './list_items/TaskItem';
import qs from 'querystring'
import {getRequestParams} from './custom_views/insta_custom_views/utils_android/CookieHelper'
import moment from 'moment'
import PayoutItem from './list_items/PayoutItemAndroid';
import { getHttpParams } from './custom_views/insta_custom_views/utils_android/UrlHelperAndroid';
class Payouts extends React.Component {
    constructor(){
        super()
        this.state = {
            payouts: [],
            done : false
        }
    }
    componentDidMount() {
        var params = getRequestParams()
        console.log("params = " + JSON.stringify(params))
        // console.log("asd = " + this.props.location.asd)
        fetch("https://app-coins.ru/api/getPayHistory", getHttpParams(params)).then((response) => response.json())
            .then((responseData) => {
                if (responseData.response == 1 || responseData.response == 2) {
                    var payouts = responseData.data
                    for (let index = 0; index < payouts.length; index++) {
                        var payout = payouts[index];
                        var timestamp = moment.unix(payout.time / 1000)
                        var formattedTime = timestamp.format("DD.MM.YYYY")
                        payout.time = formattedTime
        
                        switch (payout.status) {
                            case "success":
                                payout.status_text = "успешно"
                                payout.status_type = 0
                                break; 
                                    case "error":
                                    payout.status_text = "ошибка"
                                    payout.status_type = 1
                                    break;
                                        case "not_completed":
                                        payout.status_text = "в процессе"
                                        payout.status_type = 2
                                        break;
                        
                            default:
                                break;
                        }
                        
                    }
                    console.log("payouts = " + JSON.stringify(payouts))
                    if (payouts.length > 0){
                        this.setState({
                            payouts: payouts.reverse(),
                            done: true
                        })
                        
                    } else {
                        this.setState({
                            payouts: [],
                            done: true
                        })
                    }
        
                }
                
                console.log("getPayHistory" + JSON.stringify(responseData))
            })
    }
    getPayout = () => {
        //window.location.href = '/payout?back=payouts&testReactJS=1'
        this.props.onPayoutClicked()
    }
    render() {
        return (
            <div className="block padding_16">
               
                <div onClick={this.getPayout} className="task_btn ref_btn_width">Заказать выплату</div>

                <div className={this.state.payouts.length > 0 ? 'pay_history_container' : 'pay_history_container_empty'}>
                    <p className="task_name refs_title">История выплат</p>
                    {this.state.payouts.map((item, index) => (
                        <PayoutItem payout={item} key={index}/>
                    ))}
                </div>
            </div>
        );
    }
}
export default Payouts;