import React from 'react';
import qs from 'querystring'
import {getRequestParams} from './custom_views/insta_custom_views/utils_android/CookieHelper'
import { copyId } from './custom_views/insta_custom_views/utils_android/AndroidMethods';
import Cookies from 'universal-cookie';
import FriendsItem from './list_items/FriendsItemAndroid';
class Referals extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            friends: [],
            refsCount : 0,
            done : false
        }
    }
    componentDidMount() {
        var params = getRequestParams()
        fetch("https://app-coins.ru/api/getMyFriends", {
                method: 'POST',
                headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
                body: qs.stringify(params)
            }).then((response) => response.json())
            .then((responseData) => {
                if (responseData.response == 1 || responseData.response == 2) {
                    this.setState({friends : responseData.friends, refsCount : (responseData.countFriends != undefined ? responseData.countFriends : 0), done : true})
                }
                console.log("getMyFriends" + JSON.stringify(responseData))
            })
    }
    copyRefText = () => {
        console.log("copy")
        const cookies = new Cookies()
        copyId("Хочешь зарабатывать до 1 000 рублей в сутки не выходя из дома? Тогда скачай приложение в Google Play или AppStore и начни зарабатывать прямо сейчас. Первые 2 рубля получишь только за использование этого промокода: " + this.props.ref_id + " http://appmob.ru", "Ссылка скопирована")
    }
    render() {
        return (
            <div className="block padding_16">
                <p className="task_name bot_16">Обеспечь себя пассивным доходом. Пригласи друга и
                    получай <strong>15% от его заработка.</strong></p>
                <div onClick={this.copyRefText} className="open_support_btn">Скопировать реферальную ссылку</div>
                
                <div className={this.state.friends.length > 0 ? 'pay_history_container' : 'pay_history_container_empty'}>
                    <p className="task_name refs_title">Всего рефералов: {this.state.refsCount}</p>
                    <p className="task_name refs_title">Мои рефералы:</p>
                    {this.state.friends.map((item, index) => (
                        <FriendsItem friend={item} key={index}/>
                    ))}
                    
                </div>
            </div>
        );
    }
}
export default Referals;