import React from 'react';

const Loader = ({ style, bg_style }) => {
    return (
        <div style={bg_style || {}} className="account-content-loader loader-insta progress_bgstyle">
            <div className="account-content-loader loader-insta progress_bgstyle">
                <div style={style || {}} className="lds-ring loader-earn-container">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    );
};

export default Loader;
